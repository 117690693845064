import { IApiGateway } from '@/modules/api/api.interface'

import { IIntegrationRepository } from './integration.repository.interface'
import {
  IntegrationCreateOrUpdateBody,
  IntegrationCreateResponse,
  IntegrationDeleteResponse,
  IntegrationResponse,
  IntegrationsResponse,
  IntegrationUpdateResponse,
} from './integration.types'

export class IntegrationRepository implements IIntegrationRepository {
  constructor(private api: IApiGateway) {}

  async getAll(organizationId: string): Promise<IntegrationsResponse> {
    return this.api.get<IntegrationsResponse>(`organizations/${organizationId}/integrations`)
  }

  async get(organizationId: string, integrationId: string): Promise<IntegrationResponse> {
    return this.api.get<IntegrationResponse>(`organizations/${organizationId}/integrations/${integrationId}`)
  }

  async create(organizationId: string, body: IntegrationCreateOrUpdateBody): Promise<IntegrationCreateResponse> {
    return this.api.post<IntegrationCreateResponse>(`organizations/${organizationId}/integrations`, body)
  }

  async update(
    organizationId: string,
    integrationId: string,
    body: IntegrationCreateOrUpdateBody,
  ): Promise<IntegrationUpdateResponse> {
    return this.api.put<IntegrationUpdateResponse>(
      `organizations/${organizationId}/integrations/${integrationId}`,
      body,
    )
  }

  async delete(organizationId: string, integrationId: string): Promise<IntegrationDeleteResponse> {
    return this.api.delete<IntegrationDeleteResponse>(`organizations/${organizationId}/integrations/${integrationId}`)
  }

  async toggle(organizationId: string, integrationId: string): Promise<IntegrationResponse> {
    return this.api.put<IntegrationResponse>(`organizations/${organizationId}/integrations/${integrationId}/toggle`)
  }
}
