import { Outlet, createFileRoute } from '@tanstack/react-router'
import { Building, HeartHandshake, MailIcon, WorkflowIcon } from 'lucide-react'

import { Sidebar } from '@/components/sidebar'

const SETTINGS_SIDEBAR_LINKS = [
  {
    exact: true,
    icon: Building,
    label: 'Organizations',
    to: '/settings',
  },
  {
    icon: WorkflowIcon,
    label: 'Integrations',
    to: '/settings/integrations',
  },
  {
    icon: HeartHandshake,
    label: 'Recruiters',
    to: '/settings/recruiters',
  },
  {
    icon: MailIcon,
    label: 'Emails templates',
    to: '/settings/emails-templates',
  },
]

export const Route = createFileRoute('/_authenticated/settings')({
  component: Settings,
})

function Settings() {
  return (
    <div className="flex">
      <Sidebar links={SETTINGS_SIDEBAR_LINKS} />
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  )
}
