import { IRecruiterRepository } from '@/modules/recruiter/core/recruiter.repository.interface'
import { CreateOrUpdateRecruiterData } from '@/modules/recruiter/core/recruiter.schema'
import {
  RecruiterMinimalResponseDto,
  RecruiterResponseDto,
  UpdateRecruiterResponseDto,
} from '@/modules/recruiter/core/recruiter.types'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'

export class RecruiterService {
  constructor(private recruiterRepository: IRecruiterRepository) {}

  async getAll(): Promise<RecruiterResponseDto[]> {
    return this.recruiterRepository.getAll()
  }

  async getAllMinimal(): Promise<RecruiterMinimalResponseDto[]> {
    return this.recruiterRepository.getAllMinimal()
  }

  async get(recruiterId: string): Promise<RecruiterResponseDto> {
    return this.recruiterRepository.get(recruiterId)
  }

  async update(recruiterId: string, data: CreateOrUpdateRecruiterData): Promise<UpdateRecruiterResponseDto> {
    return this.recruiterRepository.update(recruiterId, data)
  }

  async activate(recruiterId: string, organizationId: string): Promise<IdResponseDto> {
    return this.recruiterRepository.activate(recruiterId, organizationId)
  }

  async create(data: CreateOrUpdateRecruiterData): Promise<void> {
    return this.recruiterRepository.create(data)
  }
}
