import { createFileRoute, redirect } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { integrationsQueryOptions } from '@/modules/integration/presentation/hooks/useIntegrations'
import { IntegrationsPage } from '@/modules/integration/presentation/pages/Integrations'

export const Route = createFileRoute('/_authenticated/settings/integrations')({
  beforeLoad: () => {
    return {
      meta: {
        title: 'Settings - Integrations',
      },
    }
  },
  component: IntegrationsPage,
  loader: async ({ context: { auth, queryClient } }) => {
    if (!auth.user || !auth.user.organizationIds || auth.user.organizationIds.length === 0) {
      return redirect({
        to: '/settings',
      })
    }
    await queryClient.ensureQueryData(integrationsQueryOptions(auth.user.organizationIds[0]))
  },
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Integrations" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
})
