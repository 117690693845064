import { queryOptions, useMutation, useQuery } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { RecruiterRepository } from '@/modules/recruiter/core/recruiter.repository'
import { CreateOrUpdateRecruiterData } from '@/modules/recruiter/core/recruiter.schema'
import { RecruiterService } from '@/modules/recruiter/core/recruiter.service'
import { RecruiterMinimalResponseDto, RecruiterResponseDto } from '@/modules/recruiter/core/recruiter.types'

const apiGateway = new ApiGateway()
const recruiterRepository = new RecruiterRepository(apiGateway)
const recruiterService = new RecruiterService(recruiterRepository)

const recruitersQueryOptions = queryOptions({
  queryFn: () => recruiterService.getAll(),
  queryKey: ['recruiters'],
})

const recruitersMinimalQueryOptions = queryOptions({
  queryFn: () => recruiterService.getAllMinimal(),
  queryKey: ['recruiters', 'minimal'],
})

const recruiterQueryOptions = (recruiterId: string) =>
  queryOptions({
    queryFn: () => recruiterService.get(recruiterId),
    queryKey: ['recruiter', recruiterId],
  })

const useRecruiter = (recruiterId?: string) => {
  return useQuery({
    enabled: !!recruiterId,
    ...recruiterQueryOptions(recruiterId!),
  })
}

const useRecruitersMinimal = <T,>({ select }: { select?: (data: RecruiterMinimalResponseDto[]) => T } = {}) => {
  return useQuery({ ...recruitersMinimalQueryOptions, select })
}
const useRecruiters = <T,>({ select }: { select?: (data: RecruiterResponseDto[]) => T } = {}) => {
  return useQuery({ ...recruitersQueryOptions, select })
}

const useUpdateRecruiter = () => {
  return useMutation({
    mutationFn: ({ body, id }: { id: string; body: CreateOrUpdateRecruiterData }) => recruiterService.update(id, body),
  })
}

const useCreateRecruiter = () => {
  return useMutation({
    mutationFn: (data: CreateOrUpdateRecruiterData) => recruiterService.create(data),
  })
}

const useActivateRecruiter = (recruiterId: string) => {
  return useMutation({
    mutationFn: ({ organizationId }: { organizationId: string }) =>
      recruiterService.activate(recruiterId, organizationId),
  })
}

export { recruitersQueryOptions }
export {
  useRecruiters,
  useRecruitersMinimal,
  useUpdateRecruiter,
  useCreateRecruiter,
  useActivateRecruiter,
  useRecruiter,
}
