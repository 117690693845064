import { queryOptions, useMutation, useQuery } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { IntegrationRepository } from '@/modules/integration/core/integration.repository'
import { IntergrationService } from '@/modules/integration/core/integration.service'
import { IntegrationCreateOrUpdateBody } from '@/modules/integration/core/integration.types'

const apiGateway = new ApiGateway()
const integrationRepository = new IntegrationRepository(apiGateway)
const integrationService = new IntergrationService(integrationRepository)

const integrationsQueryOptions = (organizationId: string) =>
  queryOptions({
    queryFn: () => integrationService.getAll(organizationId),
    queryKey: ['integrations', organizationId],
  })

const integrationQueryOptions = (organizationId: string, integrationId: string) =>
  queryOptions({
    queryFn: () => integrationService.get(organizationId, integrationId),
    queryKey: ['integrations', organizationId, integrationId],
  })

const useGetIntegration = (organizationId: string, integrationId?: string) => {
  return useQuery({
    enabled: !!integrationId,
    ...integrationQueryOptions(organizationId, integrationId!),
  })
}

const useCreateIntegration = () => {
  return useMutation({
    mutationFn: ({ body, organizationId }: { organizationId: string; body: IntegrationCreateOrUpdateBody }) =>
      integrationService.create(organizationId, body),
  })
}

const useUpdateIntegration = () => {
  return useMutation({
    mutationFn: ({
      body,
      integrationId,
      organizationId,
    }: {
      organizationId: string
      integrationId: string
      body: IntegrationCreateOrUpdateBody
    }) => integrationService.update(organizationId, integrationId, body),
  })
}

const useToggleIntegration = () => {
  return useMutation({
    mutationFn: ({ integrationId, organizationId }: { organizationId: string; integrationId: string }) =>
      integrationService.toggle(organizationId, integrationId),
  })
}

export { integrationsQueryOptions, integrationQueryOptions }
export { useCreateIntegration, useUpdateIntegration, useToggleIntegration, useGetIntegration }
