import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { aventaAdminEmailTemplatesQueryOptions } from '@/modules/aventa-admin/presentation/hooks/useAventaAdmin'
import { AventaAdmin } from '@/modules/aventa-admin/presentation/pages/AventaAdmin'

export const Route = createFileRoute('/_authenticated/settings/4v3ntAdm1n')({
  component: () => <AventaAdmin />,
  loader: ({ context: { queryClient } }) => queryClient.ensureQueryData(aventaAdminEmailTemplatesQueryOptions),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Admin emails templates" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
})
