import { queryOptions, useMutation, useQuery } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { OrganizationRepository } from '@/modules/organization/core/organization.repository'
import { CreateOrUpdateEmailTemplateData } from '@/modules/organization/core/organization.schema'
import { OrganizationService } from '@/modules/organization/core/organization.service'
import {
  EmailTemplateResponse,
  OrganizationMinimalResponseDto,
  OrganizationsResponse,
} from '@/modules/organization/core/organization.types'

const apiGateway = new ApiGateway()
const organizationRepository = new OrganizationRepository(apiGateway)
const organizationService = new OrganizationService(organizationRepository)

const organizationsMinimalQueryOptions = queryOptions({
  queryFn: () => organizationService.getAllMinimal(),
  queryKey: ['organizations', 'minimal'],
})

const organizationsQueryOptions = queryOptions({
  queryFn: () => organizationService.getAll(),
  queryKey: ['organizations'],
})

const useOrganizationsMinimal = <T,>({ select }: { select?: (data: OrganizationMinimalResponseDto[]) => T } = {}) => {
  return useQuery({ ...organizationsMinimalQueryOptions, select })
}

const useOrganizations = <T,>({ select }: { select?: (data: OrganizationsResponse) => T } = {}) => {
  return useQuery({ ...organizationsQueryOptions, select })
}

const useCreateOrganization = () => {
  return useMutation({
    mutationFn: (body: FormData) => organizationService.create(body),
  })
}

const useUpdateOrganization = () => {
  return useMutation({
    mutationFn: ({ body, id }: { id: string; body: FormData }) => organizationService.update(id, body),
  })
}

const emailTemplatesQueryOptions = (organizationId: string) =>
  queryOptions({
    queryFn: () => organizationService.getEmailTemplates(organizationId),
    queryKey: ['emailTemplates', organizationId],
  })

const useEmailTemplates = <T,>({
  organizationId,
  select,
}: {
  organizationId: string
  select?: (data: EmailTemplateResponse) => T
}) => {
  return useQuery({ ...emailTemplatesQueryOptions(organizationId), select })
}

const useCreateEmailTemplates = (organizationId: string) => {
  return useMutation({
    mutationFn: ({ body }: { body: CreateOrUpdateEmailTemplateData }) =>
      organizationService.createEmailTemplates(organizationId, body),
  })
}

const useUpdateEmailTemplates = (organizationId: string) => {
  return useMutation({
    mutationFn: ({ body, templateId }: { body: CreateOrUpdateEmailTemplateData; templateId: string }) =>
      organizationService.updateEmailTemplates(organizationId, templateId, body),
  })
}

const useToggleEmailTemplates = (organizationId: string) => {
  return useMutation({
    mutationFn: ({ body, templateId }: { body: { isActive: boolean }; templateId: string }) =>
      organizationService.toggleEmailTemplates(organizationId, templateId, body),
  })
}

const useGenerateApiKey = () => {
  return useMutation({
    mutationFn: ({ organizationId }: { organizationId: string }) => organizationService.generateApiKey(organizationId),
  })
}

export { organizationsQueryOptions, organizationsMinimalQueryOptions, emailTemplatesQueryOptions }
export {
  useOrganizationsMinimal,
  useOrganizations,
  useCreateOrganization,
  useUpdateOrganization,
  useEmailTemplates,
  useCreateEmailTemplates,
  useUpdateEmailTemplates,
  useToggleEmailTemplates,
  useGenerateApiKey,
}
