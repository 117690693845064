import { IApiGateway } from '@/modules/api/api.interface'

import { IAventaAdminRepository } from './aventa-admin.repository.interface'
import {
  AventaAdminEmailTemplateCreateOrUpdateBody,
  AventaAdminEmailTemplateCreateResponse,
  AventaAdminEmailTemplateResponse,
  AventaAdminEmailTemplatesResponse,
  AventaAdminEmailTemplateUpdateResponse,
} from './aventa-admin.types'

export class AventaAdminRepository implements IAventaAdminRepository {
  constructor(private api: IApiGateway) {}

  async getEmailTemplates(): Promise<AventaAdminEmailTemplatesResponse[]> {
    return this.api.get<AventaAdminEmailTemplatesResponse[]>('4v3ntAdm1n/email-templates')
  }

  async getEmailTemplateById(id: string): Promise<AventaAdminEmailTemplateResponse> {
    return this.api.get<AventaAdminEmailTemplateResponse>(`4v3ntAdm1n/email-templates/${id}`)
  }

  async createEmailTemplate(
    body: AventaAdminEmailTemplateCreateOrUpdateBody,
  ): Promise<AventaAdminEmailTemplateCreateResponse> {
    return this.api.post<AventaAdminEmailTemplateCreateResponse>('4v3ntAdm1n/email-templates', body)
  }

  async updateEmailTemplate(
    id: string,
    body: AventaAdminEmailTemplateCreateOrUpdateBody,
  ): Promise<AventaAdminEmailTemplateUpdateResponse> {
    return this.api.put<AventaAdminEmailTemplateUpdateResponse>(`4v3ntAdm1n/email-templates/${id}`, body)
  }
}
