import { IOrganizationRepository } from '@/modules/organization/core/organization.repository.interface'
import {
  EmailTemplateResponse,
  OrganizationApiKeyResponse,
  OrganizationMinimalResponseDto,
  OrganizationsResponse,
} from '@/modules/organization/core/organization.types'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'

import { CreateOrUpdateEmailTemplateData } from './organization.schema'

export class OrganizationService {
  constructor(private organizationRepository: IOrganizationRepository) {}

  async getAll(): Promise<OrganizationsResponse> {
    return this.organizationRepository.getAll()
  }

  async getAllMinimal(): Promise<OrganizationMinimalResponseDto[]> {
    return this.organizationRepository.getAllMinimal()
  }

  async create(body: FormData): Promise<IdResponseDto> {
    return this.organizationRepository.create(body)
  }

  async update(id: string, body: FormData): Promise<IdResponseDto> {
    return this.organizationRepository.update(id, body)
  }

  async getEmailTemplates(organizationId: string): Promise<EmailTemplateResponse> {
    return this.organizationRepository.getEmailTemplates(organizationId)
  }

  async createEmailTemplates(organizationId: string, body: CreateOrUpdateEmailTemplateData): Promise<IdResponseDto> {
    return this.organizationRepository.createEmailTemplates(organizationId, body)
  }

  async updateEmailTemplates(
    organizationId: string,
    templateId: string,
    body: CreateOrUpdateEmailTemplateData,
  ): Promise<IdResponseDto> {
    return this.organizationRepository.updateEmailTemplates(organizationId, body, templateId)
  }

  async toggleEmailTemplates(
    organizationId: string,
    templateId: string,
    body: { isActive: boolean },
  ): Promise<IdResponseDto> {
    return this.organizationRepository.toggleEmailTemplates(organizationId, body, templateId)
  }

  async generateApiKey(organizationId: string): Promise<OrganizationApiKeyResponse> {
    return this.organizationRepository.generateApiKey(organizationId)
  }
}
