import { IAventaAdminRepository } from './aventa-admin.repository.interface'
import {
  AventaAdminEmailTemplateCreateOrUpdateBody,
  AventaAdminEmailTemplateCreateResponse,
  AventaAdminEmailTemplateResponse,
  AventaAdminEmailTemplatesResponse,
  AventaAdminEmailTemplateUpdateResponse,
} from './aventa-admin.types'

export class AventaAdminService {
  constructor(private aventaAdminRepository: IAventaAdminRepository) {}

  async getEmailTemplates(): Promise<AventaAdminEmailTemplatesResponse[]> {
    return this.aventaAdminRepository.getEmailTemplates()
  }

  async getEmailTemplateById(id: string): Promise<AventaAdminEmailTemplateResponse> {
    return this.aventaAdminRepository.getEmailTemplateById(id)
  }

  async createEmailTemplate(
    body: AventaAdminEmailTemplateCreateOrUpdateBody,
  ): Promise<AventaAdminEmailTemplateCreateResponse> {
    return this.aventaAdminRepository.createEmailTemplate(body)
  }

  async updateEmailTemplate(
    id: string,
    body: AventaAdminEmailTemplateCreateOrUpdateBody,
  ): Promise<AventaAdminEmailTemplateUpdateResponse> {
    return this.aventaAdminRepository.updateEmailTemplate(id, body)
  }
}
