import { JobFilterValues } from '@/schemas/job'
import { get } from '@/services/get'
import { post } from '@/services/post'
import { PaginatedResponse } from '@/shared/domain/entities/pagination-meta-data.entity'
import { Job, JobCreateOrUpdateInformationsBody } from '@/types/job'

/**
 * GET /jobs
 */
export function getJobs(filters: JobFilterValues) {
  return get<PaginatedResponse<Job>>('/jobs', {
    params: filters,
  })
}

/**
 * POST /jobs
 */
export function createJob(body: JobCreateOrUpdateInformationsBody) {
  return post<JobCreateOrUpdateInformationsBody, { id: string }>('/jobs', body)
}
