import { queryOptions, useMutation, useQuery } from '@tanstack/react-query'

import { useUpdateCache } from '@/hooks/useUpdateCache'
import { ApiGateway } from '@/modules/api/api.gateway'
import { AventaAdminRepository } from '@/modules/aventa-admin/core/aventa-admin.repository'
import { AventaAdminService } from '@/modules/aventa-admin/core/aventa-admin.service'
import { AventaAdminEmailTemplateCreateOrUpdateBody } from '@/modules/aventa-admin/core/aventa-admin.types'

const apiGateway = new ApiGateway()
const aventaAdminRepository = new AventaAdminRepository(apiGateway)
const aventaAdminService = new AventaAdminService(aventaAdminRepository)

const aventaAdminEmailTemplatesQueryOptions = queryOptions({
  queryFn: () => aventaAdminService.getEmailTemplates(),
  queryKey: ['aventaAdminEmailTemplates'],
})

const aventaAdminEmailTemplateQueryOptions = (emailTemplateId?: string) =>
  queryOptions({
    enabled: !!emailTemplateId,
    queryFn: () =>
      emailTemplateId
        ? aventaAdminService.getEmailTemplateById(emailTemplateId)
        : Promise.reject('No email template ID provided'),
    queryKey: ['aventaAdminEmailTemplates', emailTemplateId],
  })

const useAventaAdminEmailTemplate = (emailTemplateId?: string) => {
  return useQuery(aventaAdminEmailTemplateQueryOptions(emailTemplateId))
}

const useCreateAventaAdminEmailTemplate = () => {
  const { addToList } = useUpdateCache()

  return useMutation({
    mutationFn: ({ body }: { body: AventaAdminEmailTemplateCreateOrUpdateBody }) =>
      aventaAdminService.createEmailTemplate(body),
    onSuccess: (data) => {
      addToList(aventaAdminEmailTemplatesQueryOptions.queryKey, data)
    },
  })
}

const useUpdateAventaAdminEmailTemplate = () => {
  const { updateList, updateSingleItem } = useUpdateCache()

  return useMutation({
    mutationFn: ({
      body,
      emailTemplateId,
    }: {
      emailTemplateId: string
      body: AventaAdminEmailTemplateCreateOrUpdateBody
    }) => aventaAdminService.updateEmailTemplate(emailTemplateId, body),
    onSuccess: (data) => {
      updateSingleItem(aventaAdminEmailTemplateQueryOptions(data.id).queryKey, data)
      updateList(aventaAdminEmailTemplatesQueryOptions.queryKey, data)
    },
  })
}

export { aventaAdminEmailTemplatesQueryOptions }
export { useAventaAdminEmailTemplate, useCreateAventaAdminEmailTemplate, useUpdateAventaAdminEmailTemplate }
