import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { organizationsMinimalQueryOptions } from '@/modules/organization/presentation/hooks/useOrganizations'

export const Route = createFileRoute('/_authenticated/settings/emails-templates')({
  beforeLoad: () => {
    return {
      meta: {
        title: 'Settings - Emails templates',
      },
    }
  },
  loader: ({ context: { queryClient } }) => queryClient.ensureQueryData(organizationsMinimalQueryOptions),
  loaderDeps: ({ search: { organizationId } }) => ({
    organizationId,
  }),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Organizations" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
  validateSearch: z.object({
    organizationId: z.string().optional(),
  }),
})
