import { IApiGateway } from '@/modules/api/api.interface'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'

import { IRecruiterRepository } from './recruiter.repository.interface'
import { CreateOrUpdateRecruiterData } from './recruiter.schema'
import { RecruiterMinimalResponseDto, RecruiterResponseDto, UpdateRecruiterResponseDto } from './recruiter.types'

export class RecruiterRepository implements IRecruiterRepository {
  constructor(private api: IApiGateway) {}

  async getAll(): Promise<RecruiterResponseDto[]> {
    return this.api.get<RecruiterResponseDto[]>('recruiters')
  }

  async getAllMinimal(): Promise<RecruiterMinimalResponseDto[]> {
    return this.api.get<RecruiterMinimalResponseDto[]>('recruiters', {
      params: {
        output: 'minimal',
      },
    })
  }

  async get(recruiterId: string): Promise<RecruiterResponseDto> {
    return this.api.get<RecruiterResponseDto>(`recruiters/${recruiterId}`)
  }

  async create(data: CreateOrUpdateRecruiterData): Promise<void> {
    return this.api.post('recruiters', data)
  }

  async update(recruiterId: string, data: CreateOrUpdateRecruiterData): Promise<UpdateRecruiterResponseDto> {
    return this.api.put(`recruiters/${recruiterId}`, data)
  }

  async activate(recruiterId: string, organizationId: string): Promise<IdResponseDto> {
    return this.api.put<IdResponseDto>(`recruiters/${recruiterId}/activate/organization/${organizationId}`)
  }
}
