import { IIntegrationRepository } from './integration.repository.interface'
import {
  IntegrationCreateOrUpdateBody,
  IntegrationDeleteResponse,
  IntegrationResponse,
  IntegrationsResponse,
  IntegrationUpdateResponse,
} from './integration.types'

export class IntergrationService {
  constructor(private integrationRepository: IIntegrationRepository) {}

  async getAll(organizationId: string): Promise<IntegrationsResponse> {
    return this.integrationRepository.getAll(organizationId)
  }

  async get(organizationId: string, integrationId: string): Promise<IntegrationResponse> {
    return this.integrationRepository.get(organizationId, integrationId)
  }

  async create(organizationId: string, body: IntegrationCreateOrUpdateBody): Promise<IntegrationResponse> {
    return this.integrationRepository.create(organizationId, body)
  }

  async update(
    organizationId: string,
    integrationId: string,
    body: IntegrationCreateOrUpdateBody,
  ): Promise<IntegrationUpdateResponse> {
    return this.integrationRepository.update(organizationId, integrationId, body)
  }

  async delete(organizationId: string, integrationId: string): Promise<IntegrationDeleteResponse> {
    return this.integrationRepository.delete(organizationId, integrationId)
  }

  async toggle(organizationId: string, integrationId: string): Promise<IntegrationResponse> {
    return this.integrationRepository.toggle(organizationId, integrationId)
  }
}
