import { queryOptions, useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { CheckEmailDto } from '@/modules/user/core/schemas/check-email'
import { CreateCommentDto } from '@/modules/user/core/schemas/create-comment.schema'
import { CreateUserApplicationDto } from '@/modules/user/core/schemas/create-user-application.schema'
import { UpdatePersonalDataValues } from '@/modules/user/core/schemas/update-personal-data.schema'
import { UserRepository } from '@/modules/user/core/user.repository'
import { UserService } from '@/modules/user/core/user.service'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'

const apiGateway = new ApiGateway()
const userRepository = new UserRepository(apiGateway)
const userService = new UserService(userRepository)

const searchUsersQueryOptions = (query: string) =>
  queryOptions({
    enabled: !!query,
    queryFn: () => userService.search(query),
    queryKey: ['users/search', query],
  })

const useSearchUsers = (query: string) => {
  return useQuery(searchUsersQueryOptions(query))
}

const userQueryOptions = (id: string) =>
  queryOptions({
    queryFn: () => userService.get(id),
    queryKey: ['user', id],
  })

const useSuspenseUser = (id: string) => {
  return useSuspenseQuery(userQueryOptions(id))
}

const userPersonalDataQueryOptions = (id: string) =>
  queryOptions({
    queryFn: () => userService.getPersonalData(id),
    queryKey: ['user/personal-data', id],
  })

const useUserPersonalData = (id: string) => {
  return useQuery(userPersonalDataQueryOptions(id))
}

const useUpdatePersonalData = (id: string) => {
  return useMutation({
    mutationFn: (data: UpdatePersonalDataValues) => userService.updatePersonalData(id, data),
  })
}

const useCheckEmail = () => {
  return useMutation({
    mutationFn: (data: CheckEmailDto) => userService.checkEmail(data),
  })
}

const useCreateUserApplication = () => {
  return useMutation({
    mutationFn: (data: CreateUserApplicationDto) => userService.createApplication(data),
  })
}

const useUploadResume = () => {
  return useMutation({
    mutationFn: (data: FormData) => userService.uploadResume(data),
  })
}

const useCreateComment = (userId: string) => {
  return useMutation({
    mutationFn: (data: CreateCommentDto) => userService.createUserComment(userId, data),
  })
}

const useUpdateJobPreferences = (id: string) => {
  return useMutation({
    mutationFn: (data: UpdateJobPreferencesData) => userService.updateJobPreferences(id, data),
  })
}

export { userQueryOptions }
export {
  useSearchUsers,
  useSuspenseUser,
  useUserPersonalData,
  useUpdatePersonalData,
  useCheckEmail,
  useCreateUserApplication,
  useUploadResume,
  useCreateComment,
  useUpdateJobPreferences,
}
